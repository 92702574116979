.social {

  @media (min-width: $width-mobile-album) {
    order: 3;
  }
}

.social--center {
  margin: 50px auto;
}

.social__list {
  list-style: none;
  height: 35px;
  display: flex;
  padding-left: 0;
  display: flex;
  justify-content: center;

  @media (min-width: $width-mobile-album) {
    margin: 0;
    margin-top: 18px;
  }
}

.social__item {
  margin-right: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $main-color;
  margin-left: 10px;
  margin-right: 10px;

  &:hover,
  &:focus,
  &:active {
    background-color: $main-theme-color;
  }
}

.social__item--alternative {
  background-color: $white;

  &:hover,
  &:focus,
  &:active {
    background-color: $main-color;
  }
}

.social__icon {
  fill: $white;
}

.social__icon--alternative {
  fill: $main-theme-color;
}

.social__icon--fb {
  width: 11px;
  height: 22px;
  margin-left: 9px;
  margin-top: 4px;
}

.social__icon--li {
  width: 17px;
  height: 17px;
  margin-left: 7px;
  margin-top: 6px;
}

.social__icon--tw {
  width: 17px;
  height: 14px;
  margin-left: 7px;
  margin-top: 8px;
}

.social__item a {
  font-size: 0;
}