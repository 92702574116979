.getstarted {
  // overflow: hidden;
  max-width: 600px;
  counter-reset: stepCounter;
  padding-top: $padding-mobile;
  padding-bottom: $padding-mobile;

  @media (min-width: $width-tablet) {
    // width: 600px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding-top: $padding-desktop;
    padding-bottom: $padding-desktop;
  }

  @media (min-width: $width-tablet-album) {
    max-width: 780px;
  }
}

.getstarted__list {
  padding: 0;
  list-style: none;
  counter-reset: item;
}

.getstarted__item {
  padding-top: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-top: $padding-mobile;
  padding-bottom: $padding-mobile;
  counter-increment: item;
}

.getstarted__item::before {
  position: absolute;
  // content: "1";
  content: counters(item, ".");
  top: 0;
  left: calc(50% - 17px);
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-image: linear-gradient(260deg, #FF9800, #FF9800);
  font-size: $heading-h2-mobile-font;
  line-height: $heading-h2-mobile-line;
  color: $white;
  font-weight: 400;
  text-align: center;
}

.getstarted__item--first {
  background-image: url('../img/double-arrow-mobile.svg');
  background-position: 50% 34%;
  background-repeat: no-repeat;

  // @media (min-width: $width-mobile-album) {
  //   background-image: url('../img/double-arrow-tablet.svg');
  // }
}

.getstarted__item--first::before {
  top: 35px;
}

.getstarted__item--first::after {
  position: absolute;
  content: '';
  width: 175px;
  height: 50px;
  bottom: 34%;
  background-repeat: no-repeat;
  background-image: url('../img/double-brow-mobile.svg');

  // @media (min-width: $width-mobile-album) {
  //   width: 290px;
  //   background-image: url('../img/double-brow-tablet.svg');
  // }
}

.getstarted__item--second {
  padding-top: 138px;
  background-image: url('../img/arrows-2-mobile.svg');
  background-position: 50% 20px;
  // background-size: 60%;
  background-repeat: no-repeat;
  flex-direction: row;
  justify-content: space-around;

  // @media (min-width: $width-mobile-album) {
  //   background-image: url('../img/arrows-2-tablet.svg');
  // }

  // @media (min-width: $width-tablet-album) {
  //   background-image: url('../img/arrows-2-desktop.svg');
  // }
}

.getstarted__item--second::before {
  top: 65px;
}

.getstarted__item--third {
  padding-top: 138px;
  background-image: url('../img/arrows-3-mobile.svg');
  background-position: 50% 20px;
  // background-size: 60%;
  background-repeat: no-repeat;

  // @media (min-width: $width-mobile-album) {
  //   background-image: url('../img/arrows-3-tablet.svg');
  // }

  // @media (min-width: $width-tablet-album) {
  //   background-image: url('../img/arrows-3-desktop.svg');
  // }
}

.getstarted__item--third::before {
  top: 28px;
}

.getstarted__store {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: $padding-desktop;
  padding-bottom: 100px;
}

.getstarted__device {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 145px;
}