.cookies {
  background-color: #dddddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: $width-tablet) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.cookies__element {
  max-width: none;

  @media (min-width: $width-mobile-album) {
    // max-width: none;
  }

  @media (min-width: $width-tablet) {
    // max-width: 650px;
    // margin-left: calc(50% - 325px);
    display: flex;
  }

  @media (min-width: $width-tablet-album) {
    max-width: 1110px;
    margin: 0 auto;
  }
}

.cookies__text {
  color: #333333;
  line-height: 18px;

  @media (min-width: $width-mobile-album) {
    // max-width: none;
  }

  @media (min-width: $width-tablet) {
    // max-width: 650px;
    // margin-left: calc(50% - 325px);
    line-height: 20px;
  }

  @media (min-width: $width-tablet-album) {
    line-height: 22px;
  }
}

.cookies__link {
  color: #2D818C;
  font-weight: 400;
}

.cookies__accept {
  align-self: center;
  margin-bottom: 15px;
  background-color: transparent;

  @media (min-width: $width-tablet) {
    margin-left: 20px;
    margin-bottom: 0;
  }

  @media (min-width: $width-tablet-album) {
    margin-left: 30px;
  }
}

.cookies__accept--error {
  animation-name: acceptError;
  animation-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
  // animation-iteration-count: infinite;
  animation-duration: .8s;
}

@keyframes acceptError {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 100%;
  }
  10% {
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    transform: rotate(4deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  90% {
    transform: rotate(2deg);
  }
}

.cookies__close {
  width: 56px;
  fill: #333333;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  @media (min-width: $width-tablet) {
    width: 106px;
  }
}