.footer-nav {
  padding-top: 10px;
  padding-bottom: 10px;

  @media (min-width: $width-mobile-album) {
    flex-grow: 1;
    // max-width: 346px;
  }
}
.footer-nav__list {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding-left: 0;
  flex-wrap: wrap;

  @media (min-width: $width-mobile-album) {
    justify-content: center;
    flex-wrap: nowrap;
  }
}

.footer-nav__item {
  list-style: none;
  flex-basis: 50%;
  // padding-left: 5px;
  // padding-right: 5px;

  @media (min-width: $width-mobile-album) {
    margin-left: 20px;
    margin-right: 20px;
    flex-basis: auto;
  }
}

.footer-nav__link {
  font-size: $xs-text-mobile-font;
  line-height: $xs-text-mobile-line;
  font-weight: 400;
  color: $main-text-color;
  text-decoration: none;

  @media (min-width: $width-tablet-album) {
    font-size: $xs-text-desktop-font;
    line-height: $xs-text-desktop-line;
  }

  &:hover,
  &:focus,
  &:active {
    color: $main-theme-color;
  }
}

.footer-nav__link--alternative {
  color: $white;

  &:hover,
  &:focus,
  &:active {
    color: $main-text-color;
  }
}