.tiles {
  display: flex;
  flex-direction: column;

  @media (min-width: $width-tablet-album) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.tiles__tile {
  display: flex;
  flex-direction: column;
  max-width: 334px;
  border-radius: 26px;
  box-shadow: 0 0 40px 0 #4000000f;
  padding: $padding-mobile;
  margin-bottom: 20px;
  // min-height: 300px;

  @media (min-width: $width-mobile-album) {
    margin-left: calc(50% - 167px);
  }

  @media (min-width: $width-tablet) {
    max-width: 480px;
    margin-left: calc(50% - 260px);
  }

  @media (min-width: $width-tablet-album) {
    width: 28%;
    min-height: 492px;
    margin-left: 0;
  }
}

.tiles__tile--wide {

  @media (min-width: $width-tablet) {
    max-width: 480px;
    margin-left: calc(50% - 260px);
  }

  @media (min-width: $width-tablet-album) {
    width: 45%;
    min-height: 492px;
    margin-left: 0;
  }
}

.tiles__bottom-element {
  margin-top: auto;
  margin-bottom: 2em;
}

.tiles__upper {
  display: flex;
  flex-direction: column;

  @media (min-width: $width-tablet-album) {
    min-height: 300px;
  }
}

.tiles__bottom {
  
  @media (min-width: $width-tablet-album) {
    min-height: 290px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}