.heading--h1 {
  font-size: $heading-h1-mobile-font;
  line-height: $heading-h1-mobile-line;

  @media (min-width: $width-tablet-album) {
    font-size: $heading-h1-desktop-font;
    line-height: $heading-h1-desktop-line;
  }
}

.heading--h2 {
  font-size: $heading-h2-mobile-font;
  line-height: $heading-h2-mobile-line;

  @media (min-width: $width-tablet-album) {
    font-size: $heading-h2-desktop-font;
    line-height: $heading-h2-desktop-line;
  }
}

.heading--h3 {
  font-size: $heading-h3-mobile-font;
  line-height: $heading-h3-mobile-line;

  @media (min-width: $width-tablet-album) {
    font-size: $heading-h3-desktop-font;
    line-height: $heading-h3-desktop-line;
  }
}

.heading--h4 {
  font-size: $heading-h4-mobile-font;
  line-height: $heading-h4-mobile-line;

  @media (min-width: $width-tablet-album) {
    font-size: $heading-h4-desktop-font;
    line-height: $heading-h4-desktop-line;
  }
}

.heading--uppercase {
  text-transform: uppercase;
}

.heading--big {
  font-size: 2.2em;
  
  @media (min-width: $width-tablet-album) {
    font-size: 2.2em;
    
  }
}

.heading--vault {
  font-size: $heading-h1-mobile-font;
  line-height: $heading-h1-mobile-line;
}

.heading--white {
  color: $white;
}

.heading--white-mobile {
  color: $white;

  @media (min-width: $width-mobile-album) {
    color: $main-text-color;
    
  }
}

.heading--theme {
  color: $main-theme-color;
}

.heading--center {
  text-align: center;
}

.heading--center-mobile {
  text-align: center;

  @media (min-width: $width-tablet) {
    text-align: left;
    
  }
}

.heading--promo-big {
  @media (min-width: $width-tablet) {
    font-size: 36px;
    line-height: 46px;
    max-width: 940px;
  }

  @media (min-width: $width-tablet-album) {
    font-size: 48px;
    line-height: 58px;
    
  }
}

.heading--margin-top-big {
  margin-top: 50px;
}

.heading--margin-bottom-0 {
  margin-bottom: 0;
}

.heading__sup {
  font-size: $text-mobile-font;
  line-height: $text-mobile-line;
  text-transform: none;

  @media (min-width: $width-tablet-album) {
    font-size: $text-desktop-font;
    line-height: $text-desktop-line;
    
  }
}