.button {
  width: 175px;
  min-width: 175px;
  height: 42px;
  border-radius: 25px;
  text-decoration: none;
  font-size: $text-mobile-font;
  line-height: $text-mobile-line;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  outline: none;
  transition: all 0.1s;
}

.button--left {
  

  @media (min-width: $width-tablet) {
    margin-left: 0;
    
  }
}

.button--common {
  border: 2px solid $button-common-color;
  background-color: $white;
  color: $button-common-color;
}

.button--common:hover,
.button--common:focus {
  background-color: $button-common-color;
  color: $white;
}

.button--error {
  color: red;
  border-color: red;
}

.button--common:active {
  background-color: $button-common-color;
  color: $white;
  box-shadow: 0 2px 6px 0 #369D9880;
}

.button--alternative {
  border: 2px solid $white;
  background-color: $main-theme-color;
  color: $white;
}

.button--alternative:hover,
.button--alternative:focus {
  background-color: $white;
  color: $button-common-color;
}

.button--alternative:active {
  background-color: $white;
  color: $button-common-color;
  box-shadow: 0 2px 6px 0 #369D9880;
}

.button--action {
  background-image: linear-gradient(260deg,#F5993D,#F6BB55);
  color: $white;
  opacity: 1;
  // background-image: none;
  // background-color: #87c278;
  // filter: hue-rotate(40deg) saturate(200%);
}

.button--action:hover,
.button--action:focus {
  background-image: linear-gradient(260deg, #FB8C00, #FFB300);
  // background-image: none;
  // background-color: #6da55e;
  opacity: 1;
  box-shadow: 0 2px 6px 0 #FF980080;
}

.button--action:active {
  background-image: linear-gradient(260deg, #FB8C00, #FFB300);
  // background-image: none;
  // background-color: #6da55e;
  box-shadow: 0 2px 6px 0 #FF980080;
  opacity: 1;
}

.button--margin-top-common {
  margin-top: 30px;
}

.button--margin-top-big {
  margin-top: 60px;
}

.button--margin-bottom-common {
  margin-bottom: 30px;
}

.button--store {
  min-width: auto;
  border-radius: 7px;
  width: 149px;
  height: 43px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: 50% 50%;
  border: 2px solid $button-common-color;
  margin: 0;
}

.button--store-google {
  background-image: url('../img/btn-android.svg')
}

.button--store-apple {
  background-image: url('../img/btn-ios.svg')
}

.button--wide {
  width: 100%;
  max-width: 300px;

}

.button--trial {
  width: 200px;

  @media (min-width: $width-tablet-album) {
    width: 280px;
    
  }
}

.button--login {
  margin-top: 10px;
}

.button--link {
  border: none;
}

.button--no-border {
  border: 0;
}

.button--de {
  font-size: 13px;
  text-align: center;

  @media (min-width: $width-tablet-album) {
    font-size: inherit;
  }
}

.button--high {
  padding-top: 5px;
  padding-bottom: 5px;
}