.img--features {
  margin: 0 auto;
  display: block;
  margin-right: 0;
}

.img--features-o {
  margin-right: auto;
}

.img--store {
  // margin-right: 0;
}

.img--access {
  margin-right: 20px;
}

.img--organize {
  margin-right: 7px;
}

.img--share {
  margin-right: 3px;
}

.img--encrypt {
  margin-right: 7px;
}

.img--generate {
  margin-right: 3px;
}

.img--center {
  margin-right: auto;
}

.img__wrapper--security {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 189px;
  height: 154px;
  border-radius: 13.3px;
  box-shadow: 0 0 21px 0 #4000000f;

  @media (min-width: $width-tablet) {
    margin: 0 40px;

  }
  
  @media (min-width: $width-tablet-album) {
    margin: 0 auto;

  }
}

.img--validation {
  width: 45px;
  height: 45px;
  margin-left: calc(50% - 24px);
  margin-top: 50px;
  
  @media (min-width: $width-tablet) {
    margin-top: 100px;
  }
}

.img--checkmail {
  width: 106px;
  height: 92px;
  margin-left: calc(50% - 40px);
  margin-top: 20px;
  margin-bottom: 20px;
}

.img--help {
  width: auto;
  max-width: 100%;
  max-height: 200px;
  
  @media (min-width: $width-mobile-album) {
    // width: auto;
    // max-width: 100%;
    max-height: 400px;
  }

  @media (min-width: $width-tablet) {
    max-width: none;
    max-height: 300px;
  }

  @media (min-width: $width-tablet-album) {
    
    max-height: 350px;
  }
}

.img--faq {
  width: 100%;
  filter: hue-rotate(-35deg);
  
  @media (min-width: $width-mobile-album) {
    width: auto;
    max-width: 49%;
    max-height: 400px;
  }

}

.img--faq-wide {
  
  @media (min-width: $width-mobile-album) {
    width: auto;
    max-width: 66%;
    max-height: 400px;
  }

}