.form {
  max-width: 651px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  overflow: hidden;
}

.form--trial {
  max-width: none;
}

.form__label {
  width: 100%;
  // max-width: 293px;
  position: relative;
  border: 0;
  border-bottom: 1px solid #00000044;
  border-radius: unset;

  @media (min-width: $width-mobile-album) {
    max-width: 293px;
  }
}

.form__label--wide {
  width: 100%;
  max-width: none;

  @media (min-width: $width-mobile-album) {
    max-width: none;
  }
}

.form__label--trial {
  @media (min-width: $width-mobile-album) {
    width: 48%;
  }

  @media (min-width: $width-tablet-album) {
    width: 31%;
  }
}

.form__input {
  background-color: transparent;
  border: 0;
  width: calc(100% - 20px);
  min-height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: $sm-text-mobile-font;
  line-height: $sm-text-mobile-line;
  color: $main-text-color;
  outline-offset: -5px;

  @media (min-width: $width-tablet-album) {
    font-size: $sm-text-desktop-font;
    line-height: $sm-text-desktop-line;
  }
}

.form__input--textarea {
  min-height: 30px;
  height: 30px;
  // max-height: 200px;
  // resize: vertical;
  resize: none;
  padding-top: 14px;
  padding-bottom: 8px;
  font-family: inherit;
}

.form__input--select {
  appearance: none;
  background-image: url(../img/icon-drop.svg);
  background-repeat: no-repeat;
  background-position: 94% 50%;
  background-size: 15px 15px;
  width: 100%;
  
  @-moz-document url-prefix() {
    padding-top: 11px;
  }
}

.form__input--white {
  color: $white;
  border-bottom: 1px solid #ffffff88;
}

.form__input--white:-webkit-autofill,
.form__input--white:-webkit-autofill:hover, 
.form__input--white:-webkit-autofill:focus, 
.form__input--white:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0px 1000px #2D818Cdd inset;
  -webkit-text-fill-color: $white !important;
}

.form__input::placeholder {
  color: $text-grey;
}

.form__input--white::placeholder {
  color: #ffffff88;
}