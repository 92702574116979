.slider {
  height: 100%;
  min-height: 222px;
	overflow: hidden;
	position: relative;
  width: auto;
  border-radius: inherit;
  
  @media (min-width: $width-mobile-album) {
    min-height: 337px;
  }

  @media (min-width: $width-tablet) {
    min-height: 400px;
  }
}

.slider__slide {
  // height: 100%;
  display: flex;
  justify-content: center;
	opacity: 0;
	position: absolute;
	width: 100%;
	z-index: 0;
  transition: transform ease-in-out .5s, opacity ease-in-out .5s;
  
  @media (min-width: $width-mobile-album) {
    width: 505px;
    height: 337px;
  }
  
  @media (min-width: $width-tablet) {
    width: 600px;
    height: 400px;
    left: calc(50% - 300px);
  }
}

.slider__img {
  // height: 66%;
  // max-width: 335px;
  border-radius: 10px;
  object-fit: contain;
  height: 100%;
  
  @media (min-width: $width-mobile-album) {
    width: 100%;
    max-width: none;
  }
}

#control-block0-image1:checked ~ .slider > .slider__slide--1,
#control-block0-image2:checked ~ .slider > .slider__slide--2,
#control-block0-image3:checked ~ .slider > .slider__slide--3,
#control-block1-image1:checked ~ .slider > .slider__slide--1,
#control-block1-image2:checked ~ .slider > .slider__slide--2,
#control-block1-image3:checked ~ .slider > .slider__slide--3,
#control-block1-image4:checked ~ .slider > .slider__slide--4,
#control-block2-image1:checked ~ .slider > .slider__slide--1,
#control-block2-image2:checked ~ .slider > .slider__slide--2,
#control-block2-image3:checked ~ .slider > .slider__slide--3,
#control-block3-image1:checked ~ .slider > .slider__slide--1,
#control-block3-image2:checked ~ .slider > .slider__slide--2,
#control-block4-image1:checked ~ .slider > .slider__slide--1,
#control-block4-image2:checked ~ .slider > .slider__slide--2 {
	opacity: 1;
	z-index: 1;
}