.content {
  padding-top: $padding-mobile;
  
  @media (min-width: $width-tablet-album) {
    padding-top: $padding-desktop;
    
  }
}

.content--narrow {
  
  @media (min-width: $width-tablet) {
    order: -1;
    width: 310px;
    
  }
  
  @media (min-width: $width-tablet-album) {
    width: 480px;
    
  }

  @media (min-width: $width-desktop) {
    width: 510px;
    
  }
}

.content--business {
  @media (min-width: $width-mobile-album) {
    margin-bottom: 160px;
    
  }
  
  @media (min-width: $width-tablet) {
    padding-top: 1px;
    margin-bottom: 0;
  }
  
  @media (min-width: $width-tablet-album) {
    width: 480px;
    
  }

  @media (min-width: $width-desktop) {
    width: 510px;
    
  }
}

.content--landing {
  @media (min-width: $width-mobile-album) {
    margin-bottom: 150px;
    
  }
  
  @media (min-width: $width-tablet) {
    width: 380px;
    
  }
  
  @media (min-width: $width-tablet-album) {
    width: 480px;
    
  }

  @media (min-width: $width-desktop) {
    width: 535px;
    
  }
}

.content--flex {
  display: flex;
  justify-content: center;
}

.content--flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content--layered {
  background-color: $white;
  border-radius: 26px;
  box-shadow: 0 0 40px 0 #4000000f;
  padding: $padding-mobile;
  margin-left: 15px;
  margin-right: 15px;
  max-width: 342px;
  // margin-left: calc(50% - 167px);

  @media (min-width: $width-mobile-album) {
    width: 480px;
    max-width: none;
    margin-left: calc(50% - 260px);
  }

  @media (min-width: $width-tablet) {
    background-color: transparent;
    box-shadow: none;
    width: 380px;
    margin-left: 0;
  }
}

.content--margin-top-big {
  margin-top: 136px;

  @media (min-width: $width-tablet) {
    margin-top: 0;
    
  }
}

.content--padding-top-big {
  padding-top: 136px;

  @media (min-width: $width-tablet) {
    padding-top: 0;
    
  }

  @media (min-width: $width-tablet-album) {
    padding-top: 0;
    
  }
}

.content--padding-top-none {
  padding-top: 0;

  @media (min-width: $width-tablet-album) {
    padding-top: 0;
    
  }
}

.content--left {
  margin-left: auto;
}

.content__wrapper {
  padding: $padding-mobile;
}

.content__wrapper--white {
  background-color: $white;
  border-radius: 26px;
  box-shadow: 0 0 40px 0 #4000000f;
  padding: $padding-mobile;
  margin-left: 15px;
  margin-right: 15px;
  max-width: 342px;

  @media (min-width: $width-mobile-album) {
    max-width: 940px;
    margin: auto;
  }

  @media (min-width: $width-tablet) {
    margin-left: 15px;
    margin-right: 15px;
  }

  @media (min-width: $width-tablet-album) {
    margin: auto;
  }
}

.content__wrapper--no-margin {
  margin: 0;
}

.content__wrapper--narrow {
  max-width: 940px;
}

.content--overlayed {
  min-height: 800px;

  @media (min-width: $width-mobile-album) {
    // margin-left: calc(50% - 345px);
    min-height: 550px;
    
  }

  @media (min-width: $width-tablet) {
    min-height: 410px;
    
  }

  @media (min-width: $width-tablet-album) {
    min-height: 330px;
    
  }

  @media (min-width: $width-desktop) {
    min-height: 410px;
    
  }
}

.content--overlayed-small {
  min-height: 400px;

  @media (min-width: $width-mobile-album) {
    min-height: 300px;
    
  }
}

.content--hovering {
  position: absolute;
  background-color: $white;
  border-radius: 26px;
  box-shadow: 0 0 40px 0 #4000000f;
  padding: $padding-mobile;
  z-index: 10;
  margin-left: calc(50% - 155px);
  width: 270px;

  @media (min-width: $width-mobile-album) {
    margin-left: calc(50% - 270px);
    top: 450px;
    width: 500px;
  }

  @media (min-width: $width-tablet) {
    margin-left: calc(50% - 355px);
    top: 330px;
    width: 650px;
    padding: 30px;
  }

  @media (min-width: $width-tablet-album) {
    margin-left: calc(50% - 490px);
    top: 400px;
    width: 900px;
    padding: 40px;
  }
}

.content--hovering-landingForm {

  @media (min-width: $width-tablet) {
    position: absolute;
    bottom: 0;
    width: 698px;
    left: calc(50% - 383px);
  }

  @media (min-width: $width-tablet-album) {
    width: 940px;
    left: calc(50% - 490px);
  }
}

.content--hovering-small {

  @media (min-width: $width-mobile-album) {
    top: 0;
  }
  

  @media (min-width: $width-tablet) {
    top: 0;
  }
  

  @media (min-width: $width-tablet-album) {
    top: 0;
  }
}

.content__padding-bottom {
  
  @media (min-width: $width-tablet) {
    padding-bottom: 300px;
  }
}

.content--filler {
  min-height: 150px;
}

.content__separator {
  width: 100%;
  height: 1px;
  background-color: #d3d3d399;
}