.control {
  position: absolute;
  width: 150px;
  height: 20px;
  bottom: -35px;
  left: calc(50% - 75px);
  // background-color: aqua;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
}

.control__input {
  display: none;
}

.control__item {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.control__item::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
  border-radius: 50%;
  background: #999;
  cursor: pointer;
}

#control-block0-image1:checked ~ .control label[for="control-block0-image1"]::before,
#control-block0-image2:checked ~ .control label[for="control-block0-image2"]::before,
#control-block0-image3:checked ~ .control label[for="control-block0-image3"]::before,
#control-block1-image1:checked ~ .control label[for="control-block1-image1"]::before,
#control-block1-image2:checked ~ .control label[for="control-block1-image2"]::before,
#control-block1-image3:checked ~ .control label[for="control-block1-image3"]::before,
#control-block1-image4:checked ~ .control label[for="control-block1-image4"]::before,
#control-block2-image1:checked ~ .control label[for="control-block2-image1"]::before,
#control-block2-image2:checked ~ .control label[for="control-block2-image2"]::before,
#control-block2-image3:checked ~ .control label[for="control-block2-image3"]::before,
#control-block3-image1:checked ~ .control label[for="control-block3-image1"]::before,
#control-block3-image2:checked ~ .control label[for="control-block3-image2"]::before,
#control-block4-image1:checked ~ .control label[for="control-block4-image1"]::before,
#control-block4-image2:checked ~ .control label[for="control-block4-image2"]::before {
  background: #FF9800;
}
