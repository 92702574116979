.logo--landing {
  margin-left: 10px;
}

.logo__img {
  width: 132px;
  height: 26px;
}

.logo__img--small {
  width: 27px;
  height: 35px;
}

.logo__link {
  margin-top: 13px;
  display: block;
}

.logo__link--landing {
  margin-top: 5px;
}

.logo__link--small {
  margin-top: 5px;
  padding: 10px;
}

.logo__burger {
  width: 25px;
  height: 25px;
  padding: 15px;
  position: relative;
}

.logo__burger::before,
.logo__burger::after {
  position: absolute;
  content: '';
  width: 25px;
  height: 3px;
  top: 21px;
  border-radius: 3px;
  background-color: grey;
  transition-duration: 0.2s;
}

.logo__burger::after {
  top: 31px;
}

.logo__burger--close::before {
  transform: rotate(45deg);
  top: 26px;
}

.logo__burger--close::after {
  transform: rotate(-45deg);
  top: 26px;
}