.preview__element {
  display: flex;
  flex-direction: column;

  @media (min-width: $width-tablet-album) {
    flex-direction: row;
    min-height: 514px;
    align-items: center;
  }
}

.preview__element {
  display: flex;
  flex-direction: column;
  margin-bottom: 55px;

  @media (min-width: $width-tablet-album) {
    flex-direction: row;
    min-height: 514px;
    align-items: center;
    margin-bottom: 0;
  }
}

.preview__element--tablet-flex-row {

  @media (min-width: $width-tablet) {
    flex-direction: row;
    // min-height: 514px;
    align-items: center;
  }
}

.preview__element--height-auto {
  @media (min-width: $width-tablet-album) {
    min-height: auto;
  }
}

.preview__carousel {
  background-color: #dddddd;
  position: relative;
  // height: 226px;
  order: 1;
  border-radius: 10px;
  // max-width: 335px;
  // margin-left: calc(50% - 167px);

  @media (min-width: $width-mobile-album) {
    max-width: 505px;
    margin-left: calc(50% - 253px);
  }
  
  @media (min-width: $width-mobile-album) {
    max-width: 600px;
    margin-left: calc(50% - 300px);
  }

  @media (min-width: $width-tablet-album) {
    width: 510px;
    margin-left: 40px;
  }

  @media (min-width: $width-desktop) {
    width: 710px;
  }
}

.preview__text {
  position: relative;
  min-height: 200px;
  order: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-right: $padding-mobile;
  padding-left: $padding-mobile;

  @media (min-width: $width-tablet-album) {
    width: 510px;
    margin-left: 40px;
  }

  @media (min-width: $width-desktop) {
    width: 710px;
  }
}

.preview__content {
  
  @media (min-width: $width-mobile-album) {
    width: 430px;
    margin-left: calc(50% - 215px);
  }

  @media (min-width: $width-tablet-album) {
    margin-left: 0;
    
  }
}

.preview__list {
  list-style: none;
  counter-reset: item;
  padding-left: 62px;
}

.preview__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding-top: $padding-mobile;
  // padding-bottom: $padding-mobile;
  counter-increment: item;
  min-height: 58px;
}

.preview__item::before {
  position: absolute;
  // content: "1";
  content: counters(item, ".");
  top: 9px;
  left: -62px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid #2D818C;
  font-size: $heading-h2-mobile-font;
  line-height: $heading-h2-mobile-line;
  color: #2D818C;
  font-weight: 400;
  text-align: center;
}

.preview__item--active * {
  font-weight: bold;
}

.preview__item--active::before {
  background-color: #2D818C;
  color: $white;
}