.list {
  list-style: none;
  counter-reset: item;
  padding-left: 0;
  
  @media (min-width: $width-tablet) {
    // padding-left: 62px;

  }
}

.list--through-1 {
  counter-reset: item 1;
}

.list--through-2 {
  counter-reset: item 2;
}

.list--through-3 {
  counter-reset: item 3;
}

.list__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  counter-increment: item;
  min-height: 58px;
  padding-top: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
  
  @media (min-width: $width-tablet) {
    padding-top: 0;

  }
}

.list__item--ordered-big::before {
  position: absolute;
  // content: "1";
  content: counters(item, ".");
  top: 0;
  left: calc(50% - 17px);
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid #FF9800;
  font-size: $heading-h2-mobile-font;
  line-height: $heading-h2-mobile-line;
  color: #FF9800;
  font-weight: 400;
  text-align: center;
  
  @media (min-width: $width-tablet) {
    top: 15px;
    left: -62px;

  }
  
  @media (min-width: $width-tablet-album) {
    top: 20px;
    left: -62px;

  }
}

.list--narrow {
  max-width: 550px;

  @media (min-width: $width-tablet-album) {
    max-width: 750px;
  }
}